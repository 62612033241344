import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {
  EnvironmentProviders,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Provider, isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogModule } from '@angular/material/dialog';

import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
  StoreModule,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorInterceptor } from './interceptors/error/error-interceptor';

import { sellReducer } from './store/modules/sell/reducer';
import { settingsReducer } from './store/modules/settings/reducer';
import { unitReducer } from './store/modules/unit/reducer';
import { userReducer } from './store/modules/user/reducer';

import { registerLocaleData } from '@angular/common';
import { environment } from '../environments/environment';
import { ModalWelcomeModule } from './components/modals/modal-welcome/modal-welcome.module';
import { JwtInterceptor } from './interceptors/jwt/jwt-interceptor';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ServiceWorkerModule } from '@angular/service-worker';

const reducers: ActionReducerMap<any, any> = {
  user: userReducer,
  unit: unitReducer,
  settings: settingsReducer,
  sell: sellReducer,
};

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

const providers: Array<Provider | EnvironmentProviders> = [
  { provide: 'Window', useValue: window },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: LOCALE_ID,
    useValue: 'pt',
  },
];

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ['user', 'unit', 'settings', 'sell'],
    rehydrate: true,
  })(reducer);
}

export function errorHandlerFactory(): BugsnagErrorHandler {
  return new BugsnagErrorHandler();
}

registerLocaleData(localePt);

if (environment.BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: environment.BUGSNAG_KEY,
    appVersion: environment.version,
    releaseStage: environment.releaseStage,
  });

  providers.push({ provide: ErrorHandler, useFactory: errorHandlerFactory });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    ToastrModule.forRoot({
      closeButton: true,
    }),
    MatDialogModule,
    ModalWelcomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
