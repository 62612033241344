import { ApplicationRef, Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CheckUpdateService {
  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    @Inject('Window') public window: any,
  ) {}

  watchForUpdates(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    const appIsStable = this.appRef.isStable.pipe(
      first((isStable) => isStable)
    );
    const everySixSeconds = interval(6000);
    const everySixSecondsOnceAppIsStable = concat(appIsStable, everySixSeconds);

    everySixSecondsOnceAppIsStable.subscribe(this.check);
  }

  private check = async () => {
    const updateFound = await this.swUpdate.checkForUpdate();
    if (
      updateFound &&
      this.window.confirm('Existe uma nova versão da aplicação. Deseja atualizar?')
    ) {
      this.window.location.reload();
    }
  }
}
